export const taskModel = {
  id: 0,
  pipeline_id: 0,
  property_id: 0,
  client_id: 0,
  title: '',
  description: '',
  status: 'progress',
  due_date: '',
  agent_name: '',
  category: '',
  interest: '',
  purpose: '',
  budget: '',
  co_buyer: '',
  referrer: '',
  channel: '',
  position: 0,
  created_at: '',
  updated_at: ''
}

export default taskModel
