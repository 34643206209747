<template>
  <div v-show="showTaskManagerDialog" class="modal-backdrop fade show"></div>
  <div v-if="showTaskManagerDialog" class="modal fade show" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="overlay-loading-task" v-if="fetchTask">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#00aeff" stroke="#00aeff" stroke-width="15" r="15" cx="35" cy="100"><animate attributeName="cx" calcMode="spline" dur="2" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin="0"></animate></circle><circle fill="#00aeff" stroke="#00aeff" stroke-width="15" opacity=".8" r="15" cx="35" cy="100"><animate attributeName="cx" calcMode="spline" dur="2" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin="0.05"></animate></circle><circle fill="#00aeff" stroke="#00aeff" stroke-width="15" opacity=".6" r="15" cx="35" cy="100"><animate attributeName="cx" calcMode="spline" dur="2" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin=".1"></animate></circle><circle fill="#00aeff" stroke="#00aeff" stroke-width="15" opacity=".4" r="15" cx="35" cy="100"><animate attributeName="cx" calcMode="spline" dur="2" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin=".15"></animate></circle><circle fill="#00aeff" stroke="#00aeff" stroke-width="15" opacity=".2" r="15" cx="35" cy="100"><animate attributeName="cx" calcMode="spline" dur="2" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin=".2"></animate></circle></svg>
          </div>
          <div class="description">Guardando Cambios</div>
        </div>
        <div class="modal-header">
          <div class="task-pipeline-select-container">
            <h5 class="modal-title">Nuevo Negocio</h5>
            <select class="task-pipeline-select" name="pipeline_id" id="pipeline_id" v-model="taskData.pipeline_id">
              <option v-for="pipeline in props.pipelines" :key="pipeline.value" :value="pipeline.value">{{ pipeline.label }}</option>
            </select>
          </div>
          <button type="button" class="close" @click="closeDialog" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form method="post" @submit.prevent="saveTask()">
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">Título</label>
                  <input type="text" v-model="taskData.title" class="form-control" id="title">
                </div>
                <div class="form-group">
                  <label for="due_date">Fecha</label>
                  <input type="date" v-model="taskData.due_date" class="form-control" id="due_date" required>
                </div>
                <div class="form-group">
                  <label for="status">Estado</label>
                  <select class="form-control" id="status" v-model="taskData.status" required>
                    <option v-for="status in statusOptions" :key="status.value" :value="status.value">{{ status.label }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="agent_name">Asignado a:</label>
                  <input type="text" class="form-control" id="agent_name" v-model="taskData.agent_name" placeholder="Ingrese el Nombre del Agente">
                </div>
                <div class="form-group">
                  <label for="property_id">Propiedad por la cual se contactó:</label>
                  <select class="form-control" id="property_id" v-model="taskData.property_id" required>
                    <option v-for="property in properties" :key="property.ID" :value="property.ID">{{ property.post_title }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="category">Categoría del Inmueble</label>
                  <select class="form-control" id="category" v-model="taskData.category" required>
                    <option disabled selected value="">Seleccione una categoría</option>
                    <option v-for="category in propertyCategoriesOptions" :value="category.value" :key="category.value">{{ category.label }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="interest">Interesado en</label>
                  <select class="form-control" id="interest" v-model="taskData.interest" required>
                    <option disabled selected value="">Seleccione una opción</option>
                    <option v-for="interest in interestOptions" :key="interest" :value="interest">{{ interest }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="purpose">Propósito</label>
                  <select class="form-control" id="purpose" v-model="taskData.purpose" required>
                    <option disabled selected value="">Seleccione una opción</option>
                    <option v-for="option in purposeOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="client_id">Cliente</label>
                  <select class="form-control" id="client_id" v-model="taskData.client_id" required>
                    <option disabled selected value="">Seleccione una opción</option>
                    <option v-for="client in clients" :key="client.lead_id" :value="client.lead_id">{{ client.display_name }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="budget">Presupuesto del Cliente</label>
                  <input type="text" class="form-control" id="budget" v-model="taskData.budget" placeholder="Ingrese el presupuesto" required>
                </div>
                <div class="form-group">
                  <label for="co_buyer">Co-Comprador (Opcional)</label>
                  <input type="text" class="form-control" id="co_buyer" v-model="taskData.co_buyer" placeholder="Ingrese el Co-comprador">
                </div>
                <div class="form-group">
                  <label for="referrer">Referidor (Opcional)</label>
                  <input type="text" class="form-control" id="referrer" v-model="taskData.referrer" placeholder="Ingrese el Referidor">
                </div>
                <div class="form-group">
                  <label for="channel">Vía o Canal</label>
                  <select class="form-control" id="channel" v-model="taskData.channel" required>
                    <option disabled selected value="">Seleccione una opción</option>
                    <option v-for="channel in channelOptions" :key="channel" :value="channel">{{ channel }}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="description">Nota (Opcional)</label>
                  <textarea v-model="taskData.description" class="form-control" id="description" rows="3" placeholder="Ingrese una nota..."></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" @click="closeDialog">Cancelar</button>
            <button type="submit" class="btn btn-sm btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import taskModel from '@/models/taskModel'
import {jsonToFormData} from '@/functions'
const props = defineProps({
  pipelines: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['updateTask'])

const showTaskManagerDialog = ref(false)

const statusOptions = ref([
  {label: 'En Proceso', value: 'progress'},
  {label: 'Procesado', value: 'success'},
  {label: 'Cancelado', value: 'cancelled'}
])

const propertyCategoriesOptions = ref([
  {label: 'Casa', value: 'house'},
  {label: 'Departamento', value: 'apartment'},
  {label: 'Terreno', value: 'land'},
  {label: 'Bodega', value: 'warehouse'},
  {label: 'Edificio', value: 'building'},
  {label: 'Local', value: 'shop'},
  {label: 'Oficina', value: 'office'}
])

const purposeOptions = ref([
  {label: 'Vivienda', value: 'housing'},
  {label: 'Inversión', value: 'investment'},
  {label: 'Para Negocio', value: 'business'},
  {label: 'Otro', value: 'other'}
])

const channelOptions = ref([
  'Encuentra24',
  'CompreoAlquile',
  'Wasi',
  'Google',
  'Facebook',
  'Instagram',
  'WhatsApp',
  'Amigo',
  'Referido',
  'Otro'
])

const interestOptions = ref([
  'Alquiler',
  'Compra',
  'Alquiler con opción a compra'
])

const fetchTask = ref(false)
const taskData = ref(structuredClone(taskModel))
const fetchProperties = ref(false)
const properties = ref([])
const fetchClients = ref(false)
const clients = ref([])

const showDialog = (currentTask = null, fromPipeline = null) => {
  showTaskManagerDialog.value = true
  Promise.all([
    getProperties(),
    getClients()
  ])

  if (currentTask) {
    taskData.value = structuredClone({...currentTask})
  } else {
    taskData.value = structuredClone(taskModel)
    taskData.value.pipeline_id = fromPipeline ?? props.pipelines[0].value

  }
  document.body.classList.add('modal-open')
}

const closeDialog = () => {
  showTaskManagerDialog.value = false
  document.body.classList.remove('modal-open')
}

async function getProperties() {
  fetchProperties.value = true
  await fetch(`${taskLeadManagerAjax.ajax_url}?action=get_properties`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    properties.value = data
    fetchProperties.value = false
  })
  .catch(error => {
    console.error('Error en la solicitud:', error)
  })
}

async function getClients() {
  fetchClients.value = true
  await fetch(`${taskLeadManagerAjax.ajax_url}?action=get_clients`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    clients.value = data
    fetchClients.value = false
  })
  .catch(error => {
    console.error('Error en la solicitud:', error)
  })
}

const saveTask = () => {
  fetchTask.value = true
  const taskDataForm = jsonToFormData(taskData.value)

  let taskUpdateUrl = `${taskLeadManagerAjax.ajax_url}?action=save_task`
  if (Number(taskData.value.id) !== 0) {
    taskUpdateUrl = `${taskLeadManagerAjax.ajax_url}?action=save_task&id=${taskData.value.id}`
  }

  fetch(taskUpdateUrl, {
    method: 'POST',
    body: taskDataForm
  })
  .then(response => response.json())
  .then(data => {
    if (data.success) {
      fetchTask.value = false
      showTaskManagerDialog.value = false
      console.log('Tarea guardada con éxito')
      emit('updateTask')
    }
    else {
      console.error('Error al guardar la tarea:', data)
      alert('Error al guardar la tarea')
    }
  })
  .catch(error => {
    fetchTask.value = false
    alert(error)
    console.error('Error en la solicitud:', error)
  })
}

defineExpose({
  showDialog,
  closeDialog
})
</script>

<style scoped>
.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  overflow: hidden;
  width: 800px;
  margin: 1.75rem auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1.75rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s linear;
}

.close:hover {
  opacity: 0.75;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-x: scroll;
  height: auto;
  max-height: calc(100vh - 185px);
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #000;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.task-pipeline-select-container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.task-pipeline-select {
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 14px;
  border-left: 1px solid #ddd;
  padding-left: 15px;
  cursor: pointer;
  outline: none;
}

.overlay-loading-task {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.90);
  border-radius: calc(.3rem - 1px);
  .icon {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }

  .description {
    font-size: 17px;
    color: #656565;
    line-height: 1;
  }
}
</style>
